<template>
  <div
    class="row position-relative"
    :class="
      RoutePage == 'QuizFinal'
        ? 'm-0 p-0 w-100 big-div-quiz'
        : ' w-85 big-div'
    "
    style="z-index: 100"
    :style="getPageDesign()"
  >
    <!-- BackGroundDiv start-->
    <script2 v-if="pageDesign.pageStyles.jsCode">
      {{ pageDesign.pageStyles.jsCode }}
    </script2>
    <div
      class="bg lazyload"
      :data-bg="MainImgSrc(getImageSrc(pageDesign.imageBlock, isDesktopView))"
      v-if="pageDesign.pageStyles.imageAlign == 'no'"
      :key="getImageSrc(pageDesign.imageBlock, isDesktopView)"
      :style="
        getbackgroundImage(
          IsImageAdded,
          pageDesign.imageBlock,
          pageDesign.pageStyles.imageAlign,
          isDesktopView
        ) + getBgDivStyles()
      "
    ></div>
    <!-- BackGroundDiv end-->
    <div v-if="showAlert" class="AlertContainer">
      <div
        class="alert text-center fade show text-white alert-text"
        role="alert"
        style="background: #f27367; width: fit-content"
      >
        <span
          ><i class="fa fa-exclamation-triangle mr-2" aria-hidden="true"></i>
          {{ translateText("Select at least one option.") }}</span
        >
      </div>
    </div>
    <!-- Image Align Left -->
    <div
      class="px-0"
      v-show="showLeftImage"
      :class="[
        {
          'col-12 col-lg-12 col-xs-12': !isDesktopView,
          'image-division col-6': isDesktopView,
        },
      ]"
      :style="
        calculateImageWidth(
          pageDesign.imageBlock,
          isDesktopView,
          IsImageVariableWidthActive
        )
      "
    >
      <div :class="isDesktopView ? 'image-division' : ''">
        <div :style="height100(isDesktopView)">
          <LazyImage
            :key="getImageSrc(pageDesign.imageBlock, isDesktopView)"
            :style="
              getUniversalImageStyle(
                isDesktopView,
                pageDesign.imageBlock.style,
                IsImageVariableWidthActive,
                pageDesign.imageBlock
              ) +
              getImageRotation(pageDesign.imageBlock.style) +
              getImageOpacity(pageDesign.imageBlock.style)
            "
            :class="[
              `pic-${getSelectedPage.order}`,
              getImageSrc(pageDesign.imageBlock, isDesktopView) ==
              '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ]"
            :src="MainImgSrc(getImageSrc(pageDesign.imageBlock, isDesktopView))"
            alt
          />
        </div>
      </div>
    </div>

    <!-- Main Section -->
    <!-- Main Section -->
    <div
      :class="[!IsImageAdded ? 'col-12' : imageAddedLayout]"
      class=" "
      :style="
        getMainPaddingCss(pageDesign.pageStyles, isDesktopView) +
        getScrollCSS(
          pageDesign.pageStyles,
          isDesktopView,
          pageDesign.imageBlock.style,
          IsImageVariableWidthActive,
          pageDesign.imageBlock,
          pageDesign.pageType
        ) +
        calculateContentWidth(
          pageDesign.imageBlock,
          isDesktopView,
          IsImageVariableWidthActive,
          pageDesign.pageStyles
        )
      "
    >
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          height: 100%;
        "
      >
        <div style="overflow: hidden scroll" class="h-100">
          <div>
            <PreviewProgressBar :order="CurrentPage.order" />
          </div>
          <div
            v-for="(pro, ind) in pageDesign.blocksArray"
            :key="'preview' + ind"
            :class="['item-' + ind, pro.type === 'tooltip' ? 'tooltip-zIndex' : '']"
            :data-aos="animationType"
              :data-aos-duration="getAnimationSpeedinMillis"
              :data-aos-delay="AOSAnimationDelay(ind)"
              :data-aos-anchor="`.item-${ind == 0 ? ind : ind - 1}`"
          >
            <div
              id="preview"
              :style="getHeightWidth(pro.style)"
              class="content"
              style="position: relative"
            >
              <div
                v-if="pro.type == 'html'"
                :style="
                  getMarginCss(pro.style, isDesktopView) +
                  getColorWithOpacity(pro.style) +
                  getTextRotate(pro.style) +
                  getTextAlign(pro.style)
                "
              >
                <HtmlElement :pro="pro" :isDesktopView="isDesktopView" />
              </div>
              <div
                v-if="pro.type == 'text'"
                :style="
                  getColorCss(pro.style) +
                  getPaddingCss(pro.style) +
                  getMarginCss(pro.style, isDesktopView) +
                  getColorWithOpacity(pro.style) +
                  getTextRotate(pro.style) +
                  getLineHeight(pro.style) +
                  getTextAlign(pro.style) +
                  getFontStyle(pro.style,isDesktopView)
                "
                v-html="getTextValue(pro.content)"
              ></div>
              <div v-if="pro.type == 'option'">
                <div>
                  <!-- Radio Options -->
                  <div
                    :style="
                      getMarginCss(pro.style, isDesktopView) +
                      getTextAlign(pro.style)
                    "
                  >
                    <div class="" v-if="isIkeaClientTagQuestion">
                      <p class="m-0 p-0" style="text-align: right">
                        {{ IkeaClientCounterValue }}
                      </p>
                    </div>
                    <theDairyFairyClient
                      @dairyFairySubmit="handleDairyFairyEvent($event)"
                      :options="getOptionsArr"
                      v-if="
                        (getPreviewSettings.quizId ==
                          theDairyFairyClientObject.quizId &&
                          CurrentPage.id == theDairyFairyClientObject.quesId) ||
                        (getPreviewSettings.quizId == getOolaUser.quizId &&
                          CurrentPage.id == getOolaUser.questionId)
                      "
                    />
                    <div class="" v-else>
                      <div v-if="getCurrentSelectedOptionType === 'text'">
                        <div
                          v-if="pro.style.textAlign != 'end'"
                          :class="
                            pro.textOptionAlignment == 'vertical'
                              ? 'd-flex flex-column'
                              : 'd-flex  flex-wrap'
                          "
                          :style="
                            pro.textOptionAlignment == 'vertical'
                              ? null
                              : ` justify-content:${pro.style.textAlign}; `
                          "
                        >
                          <div
                            class="radioboxDiv d-flex align-items-start"
                            v-for="(radio, index) in getOptionsArr"
                            :key="index"
                            :class="
                              pro.textOptionAlignment == 'horizontal'
                                ? 'mr-2'
                                : 'mb-1'
                            "
                            :style="selectedCheckboxColor(pro.style)"
                          >
                            <div
                              class="d-flex align-items-center w-100"
                              :style="`justify-content:${pro.style.textAlign};`"
                            >
                              <input
                                class="mr-2"
                                type="radio"
                                :style="{
                                  height: pro.style.fontSize + 'px',
                                  width: pro.style.fontSize + 'px',
                                  position: 'relative',
                                }"
                                name="exampleRadios"
                                :id="index"
                                :value="radio"
                                @change="getRadioValue(radio)"
                                v-model="pro.selectedOption"
                              />
                              <label
                                class="m-0"
                                :style="
                                  getColorCss(pro.style) +
                                  getColorWithOpacity(pro.style) +
                                  getFontStyle(pro.style)
                                "
                                :for="index"
                              >
                             <span v-html="radio.value"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div
                          v-else
                          :class="
                            pro.textOptionAlignment == 'vertical'
                              ? 'd-flex flex-column'
                              : 'd-flex  flex-wrap'
                          "
                          :style="
                            pro.textOptionAlignment == 'vertical'
                              ? null
                              : ` justify-content:${pro.style.textAlign}; `
                          "
                        >
                          <div
                            class="radioboxDiv d-flex align-items-start"
                            v-for="(radio, index) in getOptionsArr"
                            :key="index"
                            :class="
                              pro.textOptionAlignment == 'horizontal'
                                ? 'mr-2'
                                : 'mb-1'
                            "
                            :style="selectedCheckboxColor(pro.style)"
                          >
                            <div
                              class="d-flex align-items-center w-100"
                              :style="`justify-content:${pro.style.textAlign};`"
                            >
                              <label
                                class="m-0 mr-2"
                                :style="
                                  getColorCss(pro.style) +
                                  getColorWithOpacity(pro.style) +
                                  getFontStyle(pro.style)
                                "
                                :for="index"
                              >
                              <span v-html="radio.value"></span>
                              </label>
                              <input
                                type="radio"
                                name="exampleRadios"
                                :style="{
                                  height: pro.style.fontSize + 'px',
                                  width: pro.style.fontSize + 'px',
                                  position: 'relative',
                                }"
                                :id="index"
                                :value="radio"
                                @change="getRadioValue(radio)"
                                v-model="pro.selectedOption"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="getCurrentSelectedOptionType === 'image'">
                        <div class="row">
                          <div
                            v-for="(radio, index) in getOptionsArr"
                            :key="index"
                            class="d-flex flex-column"
                            :style="
                              getDivAlignmentStyle(pro.style.textAlign) +
                              getSpaceBetweenRow(pro.style)
                            "
                            :class="
                              isDesktopView
                                ? `col-${12 / pro.OptionsPerRow}`
                                : `col-${12 / pro.OptionsPerMobile}`
                            "
                          >

                          <div
                                :style="
                                  getImageHeightWidth(
                                    pro.style,
                                    isDesktopView
                                  ) +
                                 `${
                                  pro.selectedOption == radio
                                      ? `border:2px solid ${
                                          pro.style.selectedImageIconColor ||
                                          '#000000'
                                        };padding:2px;`
                                      : null
                                  }`
                                "
                                class="ImageInputBlock position-relative"
                                @click="checkSelectedImage(radio)"
                              >
                                <LazyImage
                                  :src="
                                    ImageSrc(radio.image) ||
                                    `/images/image_placeholder.png`
                                  "
                                  alt=""
                                  :key="
                                    ImageSrc(radio.image) ||
                                    '/images/image_placeholder.png' + index
                                  "
                                  style="
                                    cursor: pointer;
                                    height: 100%;
                                    width: 100%;
                                  "
                                  :style="getBorderRadius(pro.style)"
                                />
                              </div>
                            <div>
                              <p
                                class="m-0 p-0 mt-2"
                                :style="
                                  getColorCss(pro.style) +
                                  getColorWithOpacity(pro.style) +
                                  getFontStyle(pro.style)
                                "
                                v-html="radio.value"
                              >
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="getCurrentSelectedOptionType === 'dropdown'">
                        <!--  Dropdown Start -->
                        <div class="select-div">
                          <multiselect
                            :options="getOptionsArr"
                            :multiple="false"
                            v-model="pro.selectedOption"
                            @input="getRadioValue(pro.selectedOption)"
                            class="multiSelect"
                            placeholder="Select an option"
                            :style="
                              getColorCss(pro.style) +
                              getColorWithOpacity(pro.style) +
                              getFontStyle(pro.style) +
                              QuestionPageStyle(pro.style)
                            "
                            :close-on-select="false"
                            :searchable="false"
                            label="value"
                            track-by="value"
                          >
                          </multiselect>
                        </div>
                        <!--  Dropdown End -->
                      </div>
                      <div v-if="getCurrentSelectedOptionType === 'tab'">
                        <div v-if="pro.textOptionAlignment == 'vertical'">
                          <ul
                          :style="
                               `max-width: ${isDesktopView ?  pro.style.defaultTab.maxTabWidthDesktop || 500 : pro.style.defaultTab.maxTabWidthMobile || 500 }px;
                                list-style: none;
                                display: inline-block;`
                              "
                            class="m-0 p-0"
                          >
                            <li
                              v-for="(radio, index) in getOptionsArr"
                              :key="index"
                              class="mb-2"
                              :class="isDesktopView ? 'Tab' : ''"
                              :style="`min-width:${ isDesktopView ?  pro.style.defaultTab.minTabWidthDesktop || 200 : pro.style.defaultTab.minTabWidthMobile || 200 }px; max-width: ${isDesktopView ?  pro.style.defaultTab.maxTabWidthDesktop || 500 : pro.style.defaultTab.maxTabWidthMobile || 500 }px;`"
                            >
                              <div>
                                <button
                                  @click="updateSelectedAnswer(radio)"
                                  class="btn tab-btn border w-100"
                                  :style="
                                    pro.selectedOption.value == radio.value
                                      ? getSelectedTabStyles(
                                          pro.style.selectedTab,
                                          pro.style
                                        )
                                      : getDefaultTabStyles(
                                          pro.style.defaultTab,
                                          pro.style
                                        )
                                  "
                                >
                                <span v-html="radio.value"></span>
                                </button>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div v-else class="">
                          <div
                            class="m-0 p-0 row"
                            style="display: flex; flex-wrap: wrap"
                            :style="`height:100%;max-width: 100%; justify-content:${pro.style.textAlign};`"
                          >
                            <div
                              v-for="(radio, index) in getOptionsArr"
                              :key="index"
                              style="flex-grow: 1"
                              class="mb-2"
                              :class="[
                                isDesktopView ? 'Tab' : '',
                                isDesktopView
                                  ? pro.optionSetting.horizontalOptionsPerRow
                                    ? `col-${
                                        12 /
                                        pro.optionSetting
                                          .horizontalOptionsPerRow
                                      }`
                                    : 'col-6 col-sm-6 col-md-4'
                                  : pro.optionSetting
                                      .horizontalOptionsPerRowMobile
                                  ? `col-${
                                      12 /
                                      pro.optionSetting
                                        .horizontalOptionsPerRowMobile
                                    }`
                                  : 'col-6 col-sm-6 col-md-4',
                              ]"
                            >
                              <div style="height: 100% !important">
                                <button
                                  @click="updateSelectedAnswer(radio)"
                                  class="btn tab-btn border w-100"
                                  style="height: 100% !important"
                                  :style="
                                    pro.selectedOption.value == radio.value
                                      ? getSelectedTabStyles(
                                          pro.style.selectedTab,
                                          pro.style
                                        )
                                      : getDefaultTabStyles(
                                          pro.style.defaultTab,
                                          pro.style
                                        )
                                  "
                                >
                                <span v-html="radio.value"></span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="pro.type == 'tooltip'"
                :style="`display:flex;justify-content: ${pro.style.position} ;  `"
              >
                <button
                  :style="
                    getButtonCss(pro.style, isDesktopView) +
                    getCircularBtn(pro.isBtnRounded)
                  "
                  @mouseenter="pro.isTooltipVisible = true"
                  @mouseleave="pro.isTooltipVisible = false"
                >
                  <span v-if="pro.useIcon">
                    <BIcon :icon="pro.selectedIcon" />
                  </span>

                  <span
                    v-else
                    :style="{
                      opacity: `${pro.style.textOpacity}%`,
                      fontSize: `${pro.style.fontSize}px`,
                    }"
                  >
                    {{ pro.text }}
                  </span>
                </button>
                <ToolTipComp
                  v-if="pro.isTooltipVisible"
                  :tooltipData="pro"
                  :isDesktopView="isDesktopView"
                />
              </div>
              <div
v-if="pro.type == 'imageComp'"

>
<InlineImageComp :pro="pro" :isDesktopView="isDesktopView" />
</div>
            </div>
          </div>
        </div>

        <!-- Single Ans Back Button -->
        <div v-if="checkDairyFairyCondition"  >
          <div
            :style="getHeightWidth(StaticButtonElement.style)"
            class="content"
          
          >
            <div class="w-100">
              <div
                class="d-flex"
                :style="
                  getBackNextButtonPosition(
                    StaticButtonElement,
                    CurrentPage.value
                  )
                "
              >
                <button
                class="ButtonHover"
                  style="z-index: 1000"
                  :style="
                    getCommonButtonCSS(
                      StaticButtonElement.style,
                      isDesktopView
                    ) +
                    getBackNextButtonCss(
                      StaticButtonElement.content.backButton.style
                    )
                  "
                  @click="backPage"
                  v-if="!StaticButtonElement.content.backButton.hideBackButton"
                >
                  <span
                    :style="{
                      opacity: `${StaticButtonElement.content.backButton.style.textOpacity}%`,
                      fontSize: `${StaticButtonElement.content.backButton.style.fontSize}px`,
                    }"
                    >{{ StaticButtonElement.content.backButton.text }}</span
                  >
                </button>
                <button
                  :disabled="isLoadingQuestion"
                  style="z-index: 1000"
                  v-if="!StaticButtonElement.content.nextButton.hideNextButton"
                  :style="
                    getCommonButtonCSS(
                      StaticButtonElement.style,
                      isDesktopView
                    ) +
                    getBackNextButtonCss(
                      StaticButtonElement.content.nextButton.style
                    )
                  "
                  @click="nextPage"
                  class="ButtonHover"
                >
                  <b-spinner
                    v-if="isLoadingQuestion"
                    small
                    label="Small Spinner"
                  ></b-spinner>

                  <span
                    :style="{
                      opacity: `${StaticButtonElement.content.nextButton.style.textOpacity}%`,
                      fontSize: `${StaticButtonElement.content.nextButton.style.fontSize}px`,
                    }"
                    >{{ StaticButtonElement.content.nextButton.text }}</span
                  >
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Image Align Right -->
    <div
      class="px-0"
      v-show="showRightImage"
      :class="[
        {
          'col-12 col-lg-12 col-xs-12': !isDesktopView,
          'image-division col-6': isDesktopView,
        },
      ]"
      :style="
        calculateImageWidth(
          pageDesign.imageBlock,
          isDesktopView,
          IsImageVariableWidthActive
        )
      "
    >
      <div :class="isDesktopView ? 'image-division' : ''">
        <div :style="height100(isDesktopView)">
          <LazyImage
            :key="getImageSrc(pageDesign.imageBlock, isDesktopView)"
            :style="
              getUniversalImageStyle(
                isDesktopView,
                pageDesign.imageBlock.style,
                IsImageVariableWidthActive,
                pageDesign.imageBlock
              ) +
              getImageRotation(pageDesign.imageBlock.style) +
              getImageOpacity(pageDesign.imageBlock.style)
            "
            :class="[
              `pic-${getSelectedPage.order}`,
              getImageSrc(pageDesign.imageBlock, isDesktopView) ==
              '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ]"
            :src="MainImgSrc(getImageSrc(pageDesign.imageBlock, isDesktopView))"
            alt
          />
        </div>
      </div>
    </div>

    <!-- Show Logo Footer -->
  </div>
</template>
<script>
import { getFormatedUTCTimeStamp } from "../../composibles/getUTCTimeStamp";
import { nextJumpLogicPage } from "../../composibles/singleStepPreviewApi";
import axios from "axios";
import Vue from "vue";
import VS2 from "vue-script2";
Vue.use(VS2);
import cssFunctionMixin from "../customize/mixin/cssFunction.js";
import Multiselect from "vue-multiselect";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  props: [
    "isTest",
    "isDesktopView",
    "animationType",
    "RoutePage",
    "getIsAnimationEnabled",
    "getAnimationSpeedinMillis",
    "apiToken"
  ],

  mixins: [cssFunctionMixin],
  components: {
    Multiselect,
    HtmlElement: () =>
      import(/* webpackChunkName: "HtmlElement" */ "./HtmlElement.vue"),
    theDairyFairyClient: () =>
      import(
        /* webpackChunkName: "theDairyFairyClient" */ "./specialClients/theDairyFairy.vue"
      ),
    PreviewProgressBar: () =>
      import(
        /* webpackChunkName: "PreviewProgressBar" */ "./PreviewProgressBar.vue"
      ),
    ToolTipComp: () =>
      import(/* webpackChunkName: "ToolTipComp" */ "./ToolTipComp.vue"),
      
InlineImageComp: () =>
import(
/* webpackChunkName: "InlineImageComp" */ "./components/InlineImageComp.vue"
),

  },
  data() {
    return {
      isDiaryFairyOptionValue: false,

      activeSideBar: "pageStyle",
      pageDesign: {},
      count: 0,
      SelectedValue: "",
      showAlert: false,
      email: "",
      name: "",
      phone: "",
      date: "",
      website: "",
      organisation: "",
      address_1: "",
      address_2: "",
      city: "",
      zip_code: "",
      state: "",
      country: "",
      delayIncrement: 0.2, // The delay between each item,
      isNextPageLoading: false,
    };
  },
  methods: {
    AOSAnimationDelay(index) {
      const baseDelay = parseInt(this.getAnimationSpeedinMillis) || 200;
      return this.getIsAnimationEnabled ? (index + 1) * baseDelay : 50;
    },
    handleDairyFairyEvent(option) {
      const contentIndex = this.findOptionsIndex;
      this.pageDesign.blocksArray[contentIndex].selectedOption = option;
      this.isDiaryFairyOptionValue = true;
    },

    ...mapActions([
      "goToNextPreviewPage",
      "goToBackPreviewPage",
      "updateSelectedOptionsObject",
      "createEndQuizPage",
    ]),
    ...mapMutations([
      "jumpToPreviewPage",
      "PreviewPagesStack",
      "backPageAnimation",
      "setIsLoadingQuestion",
    ]),
    getPageDesign() {
      let design =
        "text-align:" +
        this.pageDesign.pageStyles.textAlign +
        ";color:" +
        this.pageDesign.pageStyles.color +
        "; background:" +
        this.pageDesign.pageStyles.backgroundColor +
        "; width:" +
        this.pageDesign.pageStyles.width +
        "px; " + this.getQuizPageHeight(this.pageDesign.pageStyles.height,this.RoutePage,this.isTest);

      return design;
    },

    getSinglePageValues() {
      let getStartedDesignValues = {};
      if (this.getSelectedPage) {
        getStartedDesignValues = this.getSelectedPage.pageDesign;
      }
      return getStartedDesignValues;
    },

    async getRadioValue(value) {
      if (!value) return;
      if(this.isLoadingQuestion) return;
      const logicArr = this.getSelectedPage.logic;
      const index = logicArr.findIndex((x) => {
    let arr = this.parseIfStringified(x.optionIdArr); 
    return arr.some(option => option.id == value.id);
});

      if (index > -1) {
        if (this.getPreviewSettings.single_step_status) {
          let { logicType } = logicArr[index];

          if (logicType == "jumpTo") {
            let page = JSON.parse(logicArr[index].jumpTo);
            const currPageObj = this.getSelectedPage;
            const type = "push";
            await this.PreviewPagesStack({ type, currPageObj });

            const selectedOptionObj = this.createSelectedOptionObj(value);
            await this.updateSelectedOptionsObject(selectedOptionObj);
            nextJumpLogicPage({
              api_token: this.apiToken,
              page_type: page.value, // stripe , started , lead,result
              page_id: page.id,
            });
          } else {
            this.jumpLogicFunc(logicArr, index, value);
          }
        } else {
          this.jumpLogicFunc(logicArr, index, value);
        }
      } else {
        await this.processOption(value);
      }
    },
    async processOption(value) {
      try {
        const selectedOptionObj = this.createSelectedOptionObj(value);
        await this.updateSelectedOptionsObject(selectedOptionObj);
        await this.IncrementQuestionAnalyticsCount(selectedOptionObj, "normal");
      } catch (error) {
        if (error) throw error;
      }
    },

    async jumpLogicFunc(logicArr, index, value, contentIndex) {
      const selectedLogic = logicArr[index];
      if (selectedLogic.logicType == "jumpTo") {
        let jumpToObj = JSON.parse(selectedLogic.jumpTo);
        let noResultStatus = false;
        if (jumpToObj.value == "noResultPage") {
          this.$store.state.selectedOptions = {
            questionAnswer: [],
          };
          noResultStatus = true;
          jumpToObj.value = "resultPage";
        }

        const JumpToPreviewPageIndex = this.getTotalPreviewPages.findIndex(
          (x) => x.id == jumpToObj.id && x.value == jumpToObj.value
        );
        const selectedOptionObj = this.createSelectedOptionObj(value);
        if (contentIndex) {
          this.pageDesign.blocksArray[contentIndex].selectedOption = value;
        }
        await this.updateSelectedOptionsObject(selectedOptionObj);
        if (noResultStatus) {
          this.$store.state.selectedOptions = {
            questionAnswer: [],
          };
        }

        const currPageObj = this.getSelectedPage;
        const type = "push";

        let analyticsRespone = await this.IncrementQuestionAnalyticsCount(
          selectedOptionObj,
          "jumpLogic"
        );
        console.log("Analytics Response" , analyticsRespone)
        await this.PreviewPagesStack({ type, currPageObj });
          await this.jumpToPreviewPage(JumpToPreviewPageIndex);
        // if (analyticsRespone) {
        //   await this.PreviewPagesStack({ type, currPageObj });
        //   await this.jumpToPreviewPage(JumpToPreviewPageIndex);
        // }
      }
      if (selectedLogic.logicType == "redirect") {
        let URL = selectedLogic.jumpMsgOrLink;
        let pattern = /^((http|https|ftp):\/\/)/;
        if (URL) {
          if (!pattern.test(URL)) {
            URL = "http://" + URL;
          }
          // alert()
          window.open(URL);
        }
      }
      if (selectedLogic.logicType == "redirect_self") {
        let URL = selectedLogic.jumpMsgOrLink;
        let pattern = /^((http|https|ftp):\/\/)/;
        if (URL) {
          if (!pattern.test(URL)) {
            URL = "http://" + URL;
          }
          window.location.href = URL;
        }
      }
      if (selectedLogic.logicType == "endQuiz") {
        this.createEndQuizPage(selectedLogic.jumpMsgOrLink);
      }
    },
    backPage() {
      this.backPageAnimation();
      this.goToBackPreviewPage();
    },
    async IncrementQuestionAnalyticsCount(selectedOptionObj, mode) {
      if (this.isTest) {
        if (mode == "jumpLogic") return true;

        this.goToNextPreviewPage(this.getSelectedPage.value);
        return false;
      }

      let { quiz_analytic_id } = this.getSavedLeadID;


      if (quiz_analytic_id) {
        try {
          this.isNextPageLoading = true;
          this.setIsLoadingQuestion(true)
          let quizAnalyticsPayload = {
            api_token: this.apiToken || this.$route.params.id,
            question_id: this.CurrentPage.id,
            quiz_analytic_id: quiz_analytic_id,
            option_ids:
              selectedOptionObj.selectedOption &&
              "id" in selectedOptionObj.selectedOption
                ? [selectedOptionObj.selectedOption.id]
                : [],
          };

       const response =  await axios.post(`/api/question/analytic/add`, quizAnalyticsPayload);

       if(response.status == 200){
if (mode == "jumpLogic") {
  return true;
}

this.goToNextPreviewPage(this.getSelectedPage.value);
return  false
       }

        } catch (error) {
          if (error) {
            this.$toasted.show("Error occured ", {
              theme: "toasted-primary",
              type: "error",
              position: "bottom-center",
              duration: 2000,
            });
          }
        } finally {
          this.setIsLoadingQuestion(false)
          this.isNextPageLoading = false;
        }
      } 
      else {
        try {
          this.isNextPageLoading = true;
          let data = {
            api_token: this.apiToken || this.$route.params.id,
            quizId: this.getPreviewSettings.quizId,
            value_type: "Started",
            started_at: getFormatedUTCTimeStamp(),
          };

          const response = await axios.post(`/api/updateQuizAnalytic`, data);
          if (response.status == 200) {
            let { quiz_analytic_id } = response.data.data;
            if (quiz_analytic_id) {
               await this.$store.commit("assignQuizAnaylticsId", quiz_analytic_id);
              this.IncrementQuestionAnalyticsCount(selectedOptionObj, mode);
            }
          }
        } catch (error) {
          if (error) {
            this.$toasted.show("Error occured ", {
              theme: "toasted-primary",
              type: "error",
              position: "bottom-center",
              duration: 2000,
            });
          }
        } finally {
          this.isNextPageLoading = false;
        }

        // Recursive call
      }
    },

    async nextPage() {
      const selectedValue =
        this.pageDesign.blocksArray[this.findOptionsIndex].selectedOption;

      // Dairy fairy logic when next page button is clicked
      if (
        (this.CurrentPage.id == this.theDairyFairyClientObject.quesId ||
          this.CurrentPage.id == this.getOolaUser.questionId) &&
        this.isDiaryFairyOptionValue
      ) {
        const contentIndex = this.findOptionsIndex;

        const DairyFairySelectedValue =
          this.pageDesign.blocksArray[contentIndex].selectedOption;
        const selectedOptionObj = this.createSelectedOptionObj(
          DairyFairySelectedValue
        );
        await this.updateSelectedOptionsObject(selectedOptionObj);
        await this.IncrementQuestionAnalyticsCount(selectedOptionObj, "normal");
      }

      if (this.getSkipQuestion && Object.keys(selectedValue).length == 0) {
        // create Empty obj for skipping the quiz
        const EmptySelectedOptionObj = {
          questionId: this.getSelectedPage.id,
          isMasterQuestion: this.getSelectedPage.isMasterQuestion,
          questionType: this.getSelectedPage.value,
          selectedOption: {},
        };
        await this.updateSelectedOptionsObject(EmptySelectedOptionObj);
        this.goToNextPreviewPage(this.getSelectedPage.value);
        // await this.IncrementQuestionAnalyticsCount(
        //   EmptySelectedOptionObj,
        //   "normal"
        // );
      } else {
        if (Object.keys(selectedValue).length > 0) {
          const selectedOptionObj = this.createSelectedOptionObj(selectedValue);
          await this.IncrementQuestionAnalyticsCount(
            selectedOptionObj,
            "normal"
          );
        } else {
          this.showAlert = true;
          setTimeout(
            function () {
              this.showAlert = false;
            }.bind(this),
            3000
          );
        }
      }
    },
     parseIfStringified(value) {
    if (typeof value === "string") {
        try {
            // Try to parse the string as JSON
            return  JSON.parse(value);
            
        } catch (e) {
            // If an error occurs during JSON parsing, return the original string
            return value;
        }
    }
    // If the input is not a string, return it as is
    return value;
},
    async updateSelectedAnswer(value) {
      if (!value) return;
      if(this.isLoadingQuestion) return;
      const contentIndex = this.findOptionsIndex;
      const logicArr = this.getSelectedPage.logic;
      const index = logicArr.findIndex((x) => {
    let arr = this.parseIfStringified(x.optionIdArr); 
    return arr.some(option => option.id == value.id);
});

      if (index > -1) {
        if (this.getPreviewSettings.single_step_status) {
          let page = JSON.parse(logicArr[index].jumpTo);
          const currPageObj = this.getSelectedPage;
          const type = "push";

          await this.PreviewPagesStack({ type, currPageObj });
          const selectedOptionObj = this.createSelectedOptionObj(value);
          await this.updateSelectedOptionsObject(selectedOptionObj);

          nextJumpLogicPage({
            api_token: this.apiToken,
            page_type: page.value, // stripe , started , lead,result
            page_id: page.id,
          });
        } else {
          this.jumpLogicFunc(logicArr, index, value);
        }
      } else {
        await this.processSelectedOption(value, contentIndex);
      }
    },

    async processSelectedOption(value, contentIndex) {
    
      try {
        const selectedOptionObj = this.createSelectedOptionObj(value);
        this.pageDesign.blocksArray[contentIndex].selectedOption = value;
        await this.updateSelectedOptionsObject(selectedOptionObj);

        this.IncrementQuestionAnalyticsCount(selectedOptionObj, "normal");
      } catch (error) {
        if (error) throw error;
      }
    },
    createSelectedOptionObj(value) {
      const obj = new Object();
      obj.questionId = this.getSelectedPage.id;
      obj.isMasterQuestion = this.getSelectedPage.isMasterQuestion;
      obj.questionType = this.getSelectedPage.value;
      obj.selectedOption = value;
      return obj;
    },
    checkSelectedImage(value) {
      const contentIndex = this.findOptionsIndex;
      this.pageDesign.blocksArray[contentIndex].selectedOption = value;

      this.getRadioValue(value);
    },
    ImageSrc(img) {
      if(!img) return null
      let newImage;
      if (img.startsWith("/http")) {
        newImage = img.substring(1);
      } else {
        newImage = img;
      }
      if (
        newImage.indexOf("http://") === 0 ||
        newImage.indexOf("https://") === 0
      ) {
        return newImage;
      } else {
        const baseURL = process.env.VUE_APP_API_BASE_URL;
        return baseURL + newImage.substring(1);
      }
    },
    UpdateDynamicText() {
      if (this.getSavedLeadID.full_name !== "") {
        this.name = this.getSavedLeadID.full_name;
      }
      if (this.getSavedLeadID.email !== "") {
        this.email = this.getSavedLeadID.email;
      }
      if (this.getSavedLeadID.phone_number !== "") {
        this.phone = this.getSavedLeadID.phone_number;
      }
      if (this.getSavedLeadID.date !== "") {
        this.date = this.getSavedLeadID.date;
      }
      if (this.getSavedLeadID.website !== "") {
        this.website = this.getSavedLeadID.website;
      }
      if (this.getSavedLeadID.organisation !== "") {
        this.organisation = this.getSavedLeadID.organisation;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "address1") &&
        this.getSavedLeadID.address1 !== ""
      ) {
        this.address_1 = this.getSavedLeadID.address1;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "address2") &&
        this.getSavedLeadID.address2 !== ""
      ) {
        this.address_2 = this.getSavedLeadID.address2;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "city") &&
        this.getSavedLeadID.city !== ""
      ) {
        this.city = this.getSavedLeadID.city;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "country") &&
        this.getSavedLeadID.country !== ""
      ) {
        this.country = this.getSavedLeadID.country;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "state") &&
        this.getSavedLeadID.state !== ""
      ) {
        this.state = this.getSavedLeadID.state;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "zip_code") &&
        this.getSavedLeadID.zip_code !== ""
      ) {
        this.zip_code = this.getSavedLeadID.zip_code;
      }
    },
  },
  computed: {
    isLoadingQuestion() {
      return this.getIsLoadingQuestion;
    },
    getOolaUser() {
      return this.getEnv?.VUE_APP_OOLA_USER??null;
      //
    },

    theDairyFairyClientObject() {
      return {
        quizId: this.getEnv?.VUE_APP_THEDAIRYFAIRY_QUIZID??null,
        name: this.getEnv?.VUE_APP_THEDAIRYFAIRY_QUESTION_NAME??null,
        quesId: this.getEnv?.VUE_APP_THEDAIRYFAIRY_QUESTION_ID??null,
      };
    },
    checkDairyFairyCondition() {
      if (
        this.CurrentPage.id == this.theDairyFairyClientObject.quesId ||
        this.CurrentPage.id == this.getOolaUser.questionId
      ) {
        if (this.isDiaryFairyOptionValue) return true;
        return false;
      }

      return true;
    },
    StaticButtonElement() {
      const index = this.pageDesign.blocksArray.findIndex(
        (x) => x.type == "button"
      );
      return this.pageDesign.blocksArray[index];
    },
    AnimationSettings() {
      return this.getAnimationSetting;
    },
    AmimationType() {
      if (
        this.AnimationSettings &&
        typeof this.AnimationSettings !== "undefined" &&
        this.AnimationSettings.show
      ) {
        if (this.AnimationSettings.direction == "RTL") {
          if (this.animationType == "SlideIn") return "slideRTL";
          return "slideLTR";
        } else {
          if (this.animationType == "SlideIn") return "slideLTR";
          return "slideRTL";
        }
      } else {
        return null;
      }
    },
    staticButton() {
      const index = this.pageDesign.blocksArray.findIndex(
        (x) => x.type == "button"
      );
      return this.pageDesign.blocksArray[index].staticButton;
    },
    showLeftImage() {
      return (
        (this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayDesktop &&
          this.pageDesign.pageStyles.imageAlign == "left") ||
        (!this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayMobile &&
          (this.pageDesign.pageStyles.imageAlign == "left" ||
            this.pageDesign.pageStyles.imageAlign == "right"))
      );
    },
    showRightImage() {
      return (
        this.isDesktopView &&
        this.pageDesign.imageBlock.style.displayDesktop &&
        this.pageDesign.pageStyles.imageAlign == "right"
      );
    },
    ...mapGetters([
      "getIsLoadingQuestion",
      "getSelectedPage",
      "getUploadedImage",
      "getTotalPages",
      "getTotalPreviewPages",
      "getPreviewSettings",
      "getPreviewProgressBar",
      "getSavedLeadID",
      "translateText",
      "getAnimationSetting",
      "getFurnitureClientCounterData",
      "getTrackIkeaClientCounter",
      "getEnvConst",
    ]),
    getEnv() {
      // ...mapGetters(["getEnvConst"]),
      // getEnvConst
      return this.getEnvConst;
    },
    getSkipQuestion() {
      return this.CurrentPage.skip_status;
    },
    ProgressBarValues() {
      return this.getPreviewProgressBar;
    },
    CurrentPage() {
      return this.getSelectedPage;
    },
    IsImageAdded() {
      if (
        this.getImageSrc(this.pageDesign.imageBlock, this.isDesktopView) !==
          "" ||
        this.getUploadedImage
      )
        return true;
      return false;
    },
    IsImageVariableWidthActive() {
      return this.IsImageAdded && this.pageDesign.pageStyles.imageAlign != "no"
        ? true
        : false;
    },
    imageAddedLayout() {
      if (this.pageDesign.pageStyles.imageAlign == "no") return "col-12";
      if (!this.isDesktopView) return "col-12";
      return "col-12 col-xs-12 col-sm-6 col-md-6  ";
    },
    findOptionsIndex() {
      const index = this.pageDesign.blocksArray.findIndex(
        (block) => block.type == "option"
      );
      return index;
    },
    getCurrentSelectedOptionType() {
      if (this.isDesktopView) {
        return this.pageDesign.blocksArray[this.findOptionsIndex].optionSetting.optionType;
      } else {
        return (
          this.pageDesign.blocksArray[this.findOptionsIndex].optionSetting.mobileOptionsView ??
          this.pageDesign.blocksArray[this.findOptionsIndex].optionSetting.optionType
        );
      }
    },
    getOptionsArr() {
      if (this.findOptionsIndex > -1) {
        if (this.isOptionsShuffled) {
          const arrayCopy = [
            ...this.pageDesign.blocksArray[this.findOptionsIndex].options,
          ];
          for (let i = arrayCopy.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [arrayCopy[i], arrayCopy[j]] = [arrayCopy[j], arrayCopy[i]];
          }

          return arrayCopy;
        } else {
          return this.pageDesign.blocksArray[this.findOptionsIndex].options;
        }
      }
      return [];
    },
    isOptionsShuffled() {
      return Object.prototype.hasOwnProperty.call(
        this.pageDesign.blocksArray[this.findOptionsIndex],
        "shuffleOptions"
      )
        ? this.pageDesign.blocksArray[this.findOptionsIndex].shuffleOptions
        : false;
    },
    isIkeaClientTagQuestion() {
      let tagsQuestions = this.getEnv?.VUE_APP_FURNITURE_CATEGORY_QUESTION??[];
      return this.getPreviewSettings.quizId ==
        (this.getEnv?.VUE_APP_FURNITURE_CLIENT_QUIZID??null) &&
        tagsQuestions.some((data) => data.id == this.CurrentPage.id)
        ? true
        : false;
    },
    IkeaClientCounterValue() {
      if (this.isIkeaClientTagQuestion) {
        let currentTagQuestion =
          this.getEnv?.VUE_APP_FURNITURE_CATEGORY_QUESTION??[].find(
            (obj) => obj.id == this.CurrentPage.id
          );

        let tag = currentTagQuestion.type;
        let finalCounterValue =
          this.getFurnitureClientCounterData()[tag].counter;
        let currentCounterValue = this.getTrackIkeaClientCounter[tag].counter;

        return `(${currentCounterValue} / ${finalCounterValue})`;
      }
      return null;
    },
  },
  mounted() {
    this.UpdateDynamicText();
    if (
      this.getPreviewSettings.quizId ==
      this.getEnv?.VUE_APP_FURNITURE_CLIENT_QUIZID??null
    ) {
      // Add Counter Values To text
      let tagsQuestions = this.getEnv?.VUE_APP_FURNITURE_CATEGORY_QUESTION??[];

      if (tagsQuestions.some((data) => data.id == this.CurrentPage.id)) {
        // Remove Selected Answer

        this.pageDesign.blocksArray[this.findOptionsIndex].selectedOption = "";
      }
    }
  },
  created() {
    this.pageDesign = this.getSinglePageValues();
  },
  watch: {
    getTotalPages(newVal, oldVal) {
      if (oldVal.length < newVal.length) {
        this.count++;
      }
      this.pageDesign = this.getSinglePageValues();
    },
    pageDesign: {
      deep: true,
      handler: function () {
        const order = this.getSelectedPage.order;
        const value = this.getSelectedPage.value;
        const pageDesignValue = this.pageDesign;
        this.$store.commit("setUpdatePreviewPageValues", {
          pageDesignValue,
          order,
          value,
        });
      },
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.alert-text {
  padding: 4px 12px !important;
  margin: 0px;
  width: fit-content;
  border-radius: 6px !important;
}

.images-size label {
  color: #b3afb6;
}

.custom-select-aanswer {
  display: flex;
  border: 1px solid #000000;
  border-radius: 6px;
  height: 36px;
  /* background:#ededee; */
  align-items: center;
}

.custom-select-aanswer img {
  width: 22px;
  height: 22px;
  position: absolute;
  left: 24px;
}

.question-type-select {
  background-color: transparent;
  border: none;
  padding: 4px 39px !important;
  height: 36px;
  width: 100%;
}

.sidebar {
  max-height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  height: 100vh;
}

.button-group {
  color: #535353;
  outline: #c4c4c4 solid 1px;
}

.button-selected {
  background: #000000 !important;
  color: #ffffff !important;
}

.text-img-btn-selected {
  background: #ffa201 !important;
  color: #ffffff !important;
}

.size-input {
  width: 60px;
  height: 20px;
  outline: #c4c4c4 solid 1px;
  border-radius: 4px;
}

.big-div-quiz {
  padding: 0px;
}

.tab-btn {
  border-radius: 4px;
  color: #042542;
  font-weight: 500;
  border: 1px solid #48cae4;
}

.tab-btn.selected {
  background: #ffa201;
}

/* DropdownStyles */
.select-div >>> .multiselect .multiselect__option--highlight,
.select-div >>> .multiselect .multiselect__option--highlight:after {
  background: var(--dropdownHoverColor) !important;
}

.radioboxDiv input[type="radio"] {
  accent-color: var(--selectedCheckboxColor);
}

.slideRTL {
  animation-name: slide-in;
  animation-duration: var(--animation-speed);
  animation-timing-function: forwards;
  animation-fill-mode: both;
}

.slideLTR {
  animation-name: slide-out;
  animation-duration: var(--animation-speed);
  animation-timing-function: forwards;
  animation-fill-mode: both;
}
.tooltip-zIndex{ position:relative;z-index:11111;}
</style>
